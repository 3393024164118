import React, { Component } from 'react'
import { navigate } from 'gatsby'
import axios from 'axios'
import Form from 'react-jsonschema-form'

import { Button } from 'reactstrap'

import Layout from '../components/layout'
import SEO from '../components/seo'

const schema = {
    type: 'object',
    required: ['email', 'password'],
    properties: {
        email: {
            type: 'string',
            title: 'Email Address',
        },
        password: {
            type: 'string',
            title: 'Password',
        },
    },
}

const uiSchema = {
    password: {
        'ui:widget': 'password',
    },
}

class IndexPage extends Component {
    state = {
        error: null,
    }
    componentDidMount() {
        const auth = window.localStorage.getItem('auth')

        if (auth) return navigate('/bookings')
    }
    getBookingsForMonth = async () => {}

    onSubmit = async ({ formData }) => {
        try {
            const { data: token } = await axios
                .post('https://api.kindled.com.au/api/auth', formData)
                .catch(error => {
                    throw Error(error.response.status)
                })

            // set token as localstorage
            window.localStorage.setItem('auth', token)
            navigate('/bookings')
        } catch (error) {
            this.setState({
                error: 'Incorrect email or password. Please try again.',
            })
        }
    }

    render() {
        return (
            <Layout>
                <SEO
                    title="Login | Kindled Cleaning Management"
                    keywords={['Kindled']}
                />
                <h2 className="mb-4">Login</h2>
                <Form
                    className="mb-5"
                    schema={schema}
                    uiSchema={uiSchema}
                    onSubmit={this.onSubmit}
                >
                    <div>
                        {this.state.error && (
                            <p className="text-danger mb-4">
                                {this.state.error}
                            </p>
                        )}
                        <Button type="submit" color="primary">
                            Login
                        </Button>
                    </div>
                </Form>
            </Layout>
        )
    }
}
export default IndexPage
